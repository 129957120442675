import "bootstrap";
import "./sass/main.scss";
import flatpickr from "flatpickr";
import "slick-carousel";
import './flatpickr.css';

//LAZY LOADING PICTURE TAG
document.addEventListener('DOMContentLoaded', (event) => {
  let lazyImages = [].slice.call(
    document.querySelectorAll('.lazy > source')
  )

  if ('IntersectionObserver' in window && 'IntersectionObserverEntry' in window) {
    let lazyImageObserver = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          let lazyImage = entry.target;
          lazyImage.srcset = lazyImage.dataset.srcset;
          lazyImage.nextElementSibling.srcset = lazyImage.dataset.srcset;
          lazyImage.parentElement.classList.add('fly-in');
          lazyImage.parentElement.classList.remove('lazy');
          lazyImageObserver.unobserve(lazyImage);
        }
      })
    });

    lazyImages.forEach((lazyImage) => {
      lazyImageObserver.observe(lazyImage);
    })
  } else {

    // Not supported, load all images immediately
    let active = false;

    const lazyLoad = function () {
      if (active === false) {
        active = true;
        setTimeout(function () {
          lazyImages.forEach(function (lazyImage) {
            if ((lazyImage.getBoundingClientRect().top <= window.innerHeight && lazyImage.getBoundingClientRect().bottom >= 0) && getComputedStyle(lazyImage).display !== "none") {
              lazyImage.srcset = lazyImage.dataset.srcset;
              lazyImage.nextElementSibling.src = lazyImage.dataset.srcset;
              lazyImage.nextElementSibling.classList.add('fade-in');
              lazyImage.parentElement.classList.remove("lazy");

              lazyImages = lazyImages.filter(function (image) {
                return image !== lazyImage;
              });

              if (lazyImages.length === 0) {
                document.removeEventListener("scroll", lazyLoad);
                window.removeEventListener("resize", lazyLoad);
                window.removeEventListener("orientationchange", lazyLoad);
              }
            }
          });

          active = false;
        }, 200);
      }
    };

    document.addEventListener("scroll", lazyLoad);
    window.addEventListener("resize", lazyLoad);
    window.addEventListener("orientationchange", lazyLoad);
  }
})

//Smoth scrolling
// Select all links with hashes
$('a[href*="#"]')
  // Remove links that don't actually link to anything
  .not('[href="#"]')
  .not('[href="#0"]')
  .not('[href="#firstSR"]')
  .not('[href="#secondSR"]')
  .not('[href="#thirdSR"]')
  .not('[href="#forthSR"]')
  .not('[href="#fifthSR"]')
  .not('.no-smoth')
  .click(function (event) {
    // On-page links
    if (
      location.pathname.replace(/^\//, "") ==
      this.pathname.replace(/^\//, "") &&
      location.hostname == this.hostname
    ) {
      // Figure out element to scroll to
      var target = $(this.hash);
      target = target.length ? target : $("[name=" + this.hash.slice(1) + "]");
      // Does a scroll target exist?
      if (target.length) {
        // Only prevent default if animation is actually gonna happen
        event.preventDefault();
        $("html, body").animate({
            scrollTop: target.offset().top - 80
          },
          2000,
          function () {
            // Callback after animation
            // Must change focus!
            var $target = $(target);
            $target.focus();
            if ($target.is(":focus")) {
              // Checking if the target was focused
              return false;
            } else {
              $target.attr("tabindex", "-1"); // Adding tabindex for elements not focusable
              $target.focus(); // Set focus again
            }
          }
        );
      }
    }
  });

//map highlight
$(document).ready(function() {
  if($('.maphilighed').length){
    import('./js/jquery.maphilight').then(() => 
    $('.maphilighed').maphilight().defaults)
  }
  //Multi Calendar loader
  if($('.calendars-slider').length){
    import('./js/multi-calendar').then((cal) => {
      $(window).on("load resize", () => {
        let w = $(window).innerWidth();
        if (w < 768) {
          cal.default.loadMultiCalendar(1);
        } else if (w < 992) {
          cal.default.loadMultiCalendar(2)
        } else if (w > 992) {
          cal.default.loadMultiCalendar(3);
        }
      });
    })
    

  }


})

//Hover navbar dropdown
$('.dropdown-on-hover').mouseenter(() => {
  $('#navbar-d').css('display', 'block');
})
$('.dropdown-on-hover').mouseleave(() => {
  $('#navbar-d').css('display', 'none');
})

$('#navbar-d').mouseenter(() => {
  $(this).css('display', 'block')
})
$('#navbar-d').mouseleave(() => {
  $(this).css('display', 'none');
})



let calHome = flatpickr('#home-datepicker', {
  defaultDate: null,
  minDate: 'today',
  dateFormat: 'd.m.Y'
});

$('#modalTo').flatpickr({
  defaultDate: $('#calFrom').val(),
  minDate: "today",
  dateFormat: "d.m.Y"
});
$('#modalFrom').flatpickr({
  defaultDate: $('#calFrom').val(),
  minDate: "today",
  dateFormat: "d.m.Y"
});
$('#avabilityModalCalTo').flatpickr({
  defaultDate: $('#calFrom').val(),
  minDate: "today",
  dateFormat: "d.m.Y"
});
$('#avabilityModalCalFrom').flatpickr({
  defaultDate: $('#calFrom').val(),
  minDate: "today",
  dateFormat: "d.m.Y"
});


$('#datepicker').click(() => {
  calHome.open();
});

let cal = flatpickr('#home-datepicker-small', {
  defaultDate: null,
  minDate: 'today',
  dateFormat: 'd.m.Y'
});

$('#datepicker-small').click(() => {
  cal.open();
});


$("#calFrom").flatpickr({
  defaultDate: $('#calFrom').val(),
  minDate: "today",
  dateFormat: "d-m-Y"
});
$("#calTo").flatpickr({
  defaultDate: $('#calTo').val(),
  minDate: "today",
  dateFormat: "d-m-Y"
});


//SLICK SLIDER
$(document).ready(() => {
  $(".slider-main").slick({
    slidesToShow: 1,
    prevArrow: $("#leftArr"),
    nextArrow: $("#test")
  });
});



$(document).ready(() => {
  $("#slider-images").slick({
    prevArrow: $("#mainLeft"),
    nextArrow: $("#mainRight")
  });
  $("#slider-images-nav").slick({
    slidesToShow: 10,
    slidesToScroll: 1,
    asNavFor: $("#slider-images"),
    prevArrow: false,
    nextArrow: false,
    focusOnSelect: true
  });
  //Property SLider
  $("#property-slider").slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow: $("#leftArr"),
    nextArrow: $("#rightArr")
  });

  $(".about-slider").slick({
    slidesToShow: 1,
    prevArrow: $("#left"),
    nextArrow: $("#right"),
    dots: true
  });

  $(".btn-scroll").click(function (e) {
    if ($(document).scrollTop() >= $('#search-box').offset().top - 80) {
      $("html, body").animate({
          scrollTop: $("#search-box").offset().top - 80
        },
        2000
      );
    }

  });



  //searchbox time output
  $('.dropdown-button').click((ev) => {
    var e = $(ev.target).parents('.dropdown'); //fix for broken selector handling
    $(e).find('.dropdown-menu').children().each(function (i, el) {
      $(el).click(() => {
        $(e).find('input[type=hidden]').val($(this).data('value'));
        $(e).find('.output').text($(this).text())
      })
    })
  })
  if ($('.menu-under-image-small').length > 0) {
    $('.menu-trigger').click(() => {
      $('#showSmallNav').toggleClass('d-none');
    })
  }
});

$("#showMap").click(() => {
  $(".blurred-map").hide();
  $(".show-map").removeClass("d-none");
});

$("#showMoreBtn").click(() => {
  $("#hiddenComments").toggleClass("d-none");
});

$('.hideOnFirstSmall').hide();

$('#hideOnFirstTrigger').text('Suche');

$('#hideOnFirstTrigger').click(function () {
  $(this).text(function (i, v) {
    return v === 'Suche' ? 'Suche ausblenden' : 'Suche'
  });
  $('.hideOnFirstSmall').toggle()
})

$('#showMoreBtn').text('Alle anzeigen')
$('#showMoreBtn').click(function () {
  $(this).text(function (i, v) {
    return v === 'Alle anzeigen' ? 'Schließen' : 'Alle anzeigen'
  });
})


//OnScrollSticky


$(document).scroll(function () {
  var y = $(this).scrollTop();
  if ($("#stickyNavigation").length > 0) {
    var stickyPosition = $("#stickyNavigation").offset().top;
    if (y > stickyPosition) {
      $("#showOnScroll").removeClass("d-none");
      $("#showOnScroll").addClass("sticky");
    } else {
      $("#showOnScroll").removeClass("sticky");
      $("#showOnScroll").addClass("d-none");
    }
  }

});


// added by G
$(document).ready(() => {
  var letterActive = $('.letter-switch .active').data('letter');
  $('#cities-table tbody tr').hide();
  $('#cities-table tbody tr[data-letter="' + letterActive + '"]').show();

  $('.letter-switch a').on('click', (e) => {
    if (!$(e.target).hasClass('inactive')) {
      $('.letter-switch a').removeClass('active');
      $(e.target).addClass('active');
      $('#cities-table tbody tr').hide();

      $('#cities-table tbody tr[data-letter="' + $(e.target).data('letter') + '"]').show();
    }
  });
});

//ScrollSpy
//DetailsPage
if($('#startOfProperty').length){
  import('./js/scroll-spy').then((scroll) => {
    scroll.default.scrollSpy();
  })
}
//BACK TO TOP BUTTON
var btn = $('#button');

$(window).scroll(function () {
  if ($(window).scrollTop() > 300) {
    btn.addClass('show');
  } else {
    btn.removeClass('show');
  }
});
btn.on('click', function (e) {
  e.preventDefault();
  $('html, body').animate({
    scrollTop: 0
  }, '300');
});